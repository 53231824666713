
import sulu from '~/mixins/sulu';
import suluUpdate from '~/mixins/sulu-update';
import Headless from '~/entities/headless';

export default {
  name: 'headless-page',
  layout: 'default',
  mixins: [sulu, suluUpdate],
  created() {
    this.formatSuluData();
  },
  methods: {
    formatSuluData () {
      this.$set(this, 'content', new Headless(this.content));
    },
  },
};
